<template>
    <div>
        <b-row>
            <b-col sm="5">
                <b-card>
                    <b-row>
                        <b-col cols="12" class="my-1">
                            <h5>CANTIDAD DE PROVEEDORES REGISTRADOS POR RUBRO</h5>    
                        </b-col>                    
                        <b-col cols="12">
                            <vue-apex-charts
                                ref="pieThreeRef"
                                type="pie"
                                height="325"
                                class="mt-2 mb-1"
                                :options="tablePieThree.chartOptions"
                                :series="tablePieThree.series"
                            />                            
                        </b-col>                    
                    </b-row>
                </b-card>                                           
            </b-col>
            <b-col sm="7">
                <b-card>                     
                    <json-excel :data="threeTableExport()" name="cantidad_proveedores_registrados_por_rubro.xls"></json-excel>
                    <b-table
                        ref="table-three-ref"
                        :fields="fieldsThree"
                        :items="itemsThree"
                        show-empty
                        empty-text="No se encontraron registros coincidentes"
                    >   

                        <template #cell(business_area_name)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.business_area_name }}</b>
                            <span v-else>{{ data.item.business_area_name }}</span>                         
                        </template>
                    
                        <template #cell(quantity)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.quantity }}</b>
                            <span v-else>{{ data.item.quantity }}</span>                        
                        </template>
                        <template #cell(percentage)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.percentage }}</b>
                            <span v-else>{{ data.item.percentage }}</span>
                        </template>
                    
                    </b-table>
                </b-card>                
            </b-col>
        </b-row>
    </div>  
</template>

<script>

    import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es.js';
    import flatPickr from 'vue-flatpickr-component';
    import VueApexCharts from 'vue-apexcharts';
    import JsonExcel from "./download-excel";
    import axios from '@axios';

    export default {
        components: {
            flatPickr,
            VueApexCharts,
            JsonExcel
        },
        data() {
            return {
                totalThree: "",
               
                configCalendar: {
                    locale:  SpanishLocale,
                    dateFormat: 'd-m-Y'
                },
                       
                fieldsThree: [
                    {key: 'index', label: "#"},
                    {key: "business_area_name", label: "Rubro"},
                    {key: "quantity", label: "Cantidad"},
                    {key: "percentage", label: "%"}
                ],
                itemsThree: [],
                
                tablePieThree: {
                    series: [],
                    chartOptions: {
                        chart: {
                            toolbar: {
                                show: false,
                            }
                        },
                        labels: [],
                        dataLabels: {
                            enabled: true
                        },
                        legend: { show: false },
                        stroke: {
                            width: 4
                        },
                        noData: { text: "Sin datos..." }
                    }
                },
            }
        },
        beforeMount() {
            this.getDataTableThree();
        },
        methods: {           
            async getDataTableThree (){
                let response = await axios.post('/reporteria/business-areas-providers');

                let total = 0;
                response.data.map(item => { total = total + item.providers_count } );
                                
                response.data.map( (item, index) => {
                    this.itemsThree.push({
                        index: index + 1,
                        business_area_name: item.name,
                        quantity: item.providers_count,
                        percentage: ((item.providers_count*100)/total).toFixed(2) + '%',
                    });     
                    this.tablePieThree.series.push((item.providers_count*100)/total);
                    this.tablePieThree.chartOptions.labels.push(item.name)
                });
                
                this.itemsThree.push({
                    index: '',
                    business_area_name: 'TOTAL',
                    quantity: total,
                    percentage: '100%',
                    status: true
                });
                this.totalThree = total; 

            },
            threeTableExport (){

                let array = [];

                for (let a=0; a<this.itemsThree.length; a++)
                {
                    let objBody = {
                        'ITEM': a+1,
                        'RUBRO': this.itemsThree[a]["business_area_name"],
                        'CANTIDAD': this.itemsThree[a]["quantity"],
                        'PORCENTAJE':  this.itemsThree[a]["percentage"]
                    };

                    array.push(objBody);
                }
                
                array.splice(array.length-1, 1);

                let objFoot = {
                    'ITEM': '',
                    'RUBRO': 'TOTAL',
                    'CANTIDAD': this.totalThree,
                    'PORCENTAJE': '100%'
                };

                array.push(objFoot);
                
                return array;
            },
        }
    }

</script>

<style scoped>

    input[type=text] {
        border: none;
        border-radius: 0px;
        background: transparent;
        border-bottom: 1px solid #d8d6de;
    }

</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
    @import '@core/scss/vue/libs/chart-apex.scss';
</style>
